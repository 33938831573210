import { FlowAPI, FlowEditorSDK, WidgetBuilder } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import applicationConfig from '../../../.application.json';

const { appDefinitionId } = applicationConfig;
export const getOrganizeMediaUrl = ({ instanceId, instance, compId }: any) => {
  return `https://progallery.wixapps.net/photos.html?instance_id=${instanceId}&instance=${instance}&compId=${compId}&origCompId=${compId}&appDefinitionId=${appDefinitionId}$app_id=${appDefinitionId}&originPG=bob`;
};

const HELP_KB_ID = '99576567-3a38-4ece-bcdf-c75ef7f18f2e';

const refreshAppServices = (editorSDK: FlowEditorSDK) => {
  return async () => {
    editorSDK.document.application.livePreview.refresh('', {
      shouldFetchData: true,
      source: '',
    });
    editorSDK.document.application.reloadManifest();
    const isDynamicPage = !!(await editorSDK.routers.getCurrentDynamicRouting(
      '',
    ));
    isDynamicPage && editorSDK.editor.routers.refresh('');
  };
};

const setDesktopGFPP = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  t: any,
) => {
  //  @ts-expect-error
  const instance = self?.pgBob?.instance;
  //  @ts-expect-error
  const instanceId = self?.pgBob?.instanceId;
  if (!instance) {
    return;
  }
  const builder = controllerBuilder.configureConnectedComponents(
    'proGallery1',
    (connectedComponentsBuilder) => {
      return connectedComponentsBuilder.configureWidgetDesign;
    },
  );
  const buildGfpp = (b: typeof builder, platform: 'desktop' | 'mobile') => {
    return b
      .gfpp(platform as 'desktop')
      .set('mainAction1', {
        label: t('Manage Media'),
        onClick: ({ detail }) => {
          const compId = detail.componentRef.id;
          editorSDK.editor
            .openModalPanel('token', {
              url: getOrganizeMediaUrl({ instanceId, instance, compId }),
              title: t('Pro Gallery Media Manager'),
              // @ts-expect-error
              width: '100%',
              // @ts-expect-error
              height: '100%',
              shouldHideHeader: true,
            })
            .then((result) => {
              refreshAppServices(editorSDK)();
            });
        },
      })
      .set('design', {
        target: {
          role: 'proGallery1',
        },
      })
      .set('layout', {
        target: {
          role: 'proGallery1',
        },
      })
      .set('animation', {
        target: {
          role: 'proGallery1',
        },
      })
      .set('settings', {
        target: {
          role: 'proGallery1',
        },
      })
      .set('behavior' as any, {
        target: {
          role: 'proGallery1',
        },
      })
      .set('add', { behavior: 'HIDE' })
      .set('help', {
        id: HELP_KB_ID,
      });
  };

  buildGfpp(builder, 'desktop');
  buildGfpp(builder, 'mobile');
};

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  const { t } = flowAPI.translations;
  controllerBuilder.set({
    displayName: 'Pro Gallery Bob',
    nickname: 'proGalleryWidget',
  });
  setDesktopGFPP(controllerBuilder, editorSDK, t);
};
